@import "./../../variables";

.card-element-container {
    height: 40px;
    //display: flex;
    align-items: center;

    & .StripElement {
        width: 100%;
        padding: 15px;
    }
}

.stripe-logo {
    opacity: 30%;
    width: 100px;
}

.payment-discount {
    display: flex;
    flex-grow: 1;
    align-items: last baseline;
    margin-bottom: 0.5rem;

    .discount-code {
        flex: 1 0 auto;
        margin-bottom: 0.3rem;
    }

    .button {
        margin-left: 1rem;
        flex: 0 1 auto;
        height: 32.5px;
    }
}

.discount-description {
    color: $purple
}