@import '../../../variables';

.faq-item {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    height: 100%;

    .faq-icon-container {
        width: 38px;
        height: 38px;
    }

    .faq-icon {     
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background-color: $purple;
        border-radius: 50%;
        margin-right: 12px;
    
        svg {
          fill: #fff;
        }
      }

    .faq-question-container {
        flex:1;   
        margin-left: 0.7rem;
    }

    .faq-question {        
        font-size: 1.1rem;
    }

    .faq-answer {
        font-size: 1rem;
    }
}

