@import "./../variables";

.marketing-navbar {
  transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: background-color 250ms ease-in;
  transition: min-height 250ms;
  margin-left: auto;
  margin-right: auto;
  max-width: 1930px;
  min-height: 120px;

  &.white {
    background-color: white;
    box-shadow: 0 3px 20px rgba(black, 0.2);
    min-height: 80px;
  }

  &.is-transparent {
    background-color: transparent !important;
  }

  .is-light {
    transition: background-color 150ms ease-in;
    color: $grey-darkest;
    &:hover {
      color: $grey-darkest;
    }
    &.green-border {    
      &:hover {     
        border-color: $green;
      }
    }
  }

  .navbar-menu.is-active
  {
    padding-bottom: 1rem;
  }

  .navbar-burger  {
    color: $grey-darkest !important;
  }

  .navbar-burger:hover {
    background-color: transparent !important;
  }

  .navbar-burger span {
    width: 1.5rem;
    height: 2px !important;
    transition: 0.4s;
  }

  .nav-close {
    display: flex;
    align-items: center;
    justify-content: center;    
  }

  .open .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, -20px);
    transform: rotate(-45deg) translate(-5px, 1px);
  }
  
  .open .bar2 {
    opacity: 0;
  }
  
  .open .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -2.5px);
  }

  .navbar-end {
    margin-right: 2rem;
  }

  .navbar-item a {
    font-weight: 600;
  }

  .navbar-item .button {
    height: 2.8rem;
    border-radius: 10px;
  }

  .nav-logo {
    transform-origin: bottom center;
    transition: transform 250ms ease-in;
    margin-left: 3rem;
    margin-top: 0.9rem;

    &.invisible {
      transform: scale(1.2);
    }
  }

  a {
    color: $grey-darkest;
    transition: color 150ms ease-in;
    &.green-hover-link {
      &:hover {
        color: $green;
      }
    }

    &.purple-hover-link {
      &:hover {
        color: $purple;
      }
    }
    
  }

  .is-purple {
    background-color: $purple;
    color: white;
  }

  .is-beta {
    margin-left: 0.5rem;
    position: relative;
    font-weight: normal;
    font-size: 0.6rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    top: -7px;
  }

  @media only screen and (max-width: 936px) {
    min-height: 80px !important;
  }

  @media only screen and (max-width: 768px) {
    .nav-logo {
      margin-left: 0.4rem;
    }

    .nav-logo.invisible {
      transform: scale(1);
    }
  }
}
