@import "../../../variables";

.shot-column {
  width: 4.2rem;
}

.time-slot-heading {
  .field {
    margin-bottom: 0 !important;
  }
}

.quick-select-table td {
  border: none !important;
  vertical-align: middle !important;
}

.quick-select-table>tr>td:nth-child(1) {
  width: 8rem
}

.sticky-buttons {
  background-color: $grey-darker;
  position: sticky;
  bottom: 0;
  padding-top: 0.5rem;
}