@import "../../../variables";

.fc .fc-button-primary {
  background-color: transparent;
  border-color: $button-border-color;
  text-transform: none;

  &.is-hovered,
  &:hover {
    border-color: transparent;
    background-color: white;
    color: $button-text-hover-color;
  }

  text-transform: uppercase;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
  background-color 300ms ease;
}


.fc .fc-toolbar-title {
  font-size: 1.1rem;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #4C5A6F
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #4C5A6F
}

.fc .fc-button-primary:disabled {
  background-color: $grey-darker;
  border-color: $button-border-color-disabled;

  &.is-hovered,
  &:hover {
    color: $button-color;
  }
}

.fc-event-title-container {
  text-align: center;
}

.fc {
  .fc-day-disabled {
    background: $grey-darkest;
  }

  .fc-highlight {
    background-color: rgba($green, 0.3);
  }

  .fc-daygrid-day.fc-day-today {
    background: #222e44;
  }

  .fc-day-today {
    background: #222e44;
  }

  .fc-daygrid-day-number {
    color: white;
    vertical-align: bottom;
  }
}

.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;

  .fc-daygrid-day-number {
    color: #627795;
  }
}

.fc-non-business {
  background-color: $grey-darkest !important;
  opacity: 0.6;
}

.fc-scrollgrid-sync-inner {
  text-align: center;
}

.calendar-header {
  a {
    color: white !important;
  }
}

.calendar-working-days {
  display: flex;
  margin-bottom: 1rem;
}

.calendar-day-of-week-container {
  display: flex;

  .calendar-day-of-week {
    display: inline-block;
    margin-right: 1.5rem;
    min-width: 2rem;
    align-items: center;
    justify-self: center;
  }

  .calendar-day-of-week-checkbox {
    display: flex;
    justify-content: center;


    label {
      display: inline-block !important;
      line-height: 0;
    }
  }

  .calendar-day-of-week-name {
    text-align: center;
  }
}

.event-details {
  margin-top: 1rem;

  .event-details-close-button {
    float: right;
    margin-bottom: 0.5rem;
  }
}

.shooting-days-potential-day-container {
  display: flex;
  margin-top: 1rem;

  .shooting-days-potential-day-background {
    width: 25px;
    height: 25px;
    background-color: $green;
    border-width: 1px;
    border-style: solid;
    border-color: "#4C5A6F";
    opacity: 0.3;
    margin-right: 1rem;
  }
}