.email-recipients-progress-container {
  display: flex;
}

.small-circular-progress {
  text-align: center;
  max-width: 150px;
  margin-right: 1rem;
}

.small-box {
  padding: 0 !important;
  padding-top: 0.5rem !important;

  .is-size-7 {
    font-size: 0.9rem !important;
  }
}

.call-sheet-sections-list {
  .checkbox-items-container {
    max-height: 320px;
  }
}

.call-sheet-not-signed-release-icon {
  font-size: 1rem;
  margin-right: 0.3rem;
  top: -3px;
  position: relative;
}