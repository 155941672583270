// Overrides
html {
  background-color: $grey-darkest;
  height: 100vh;
  overflow: auto;
}

body {
  height: 100vh;
}

#root {
  width: fit-content;
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  flex-direction: column;
}

hr {
  margin-top: 0.4rem;
  background-color: #222e43;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select,
.select select,
.textarea {
  height: 2.5em;
}

.main-content {
  background-color: $grey-darker;
  margin-top: 50px;
  flex: 1;
}

footer {
  background-color: $grey-darkest;
}

.footer {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: $button-border;
  background-color: $grey-darkest;
  border-color: $grey-darkest;

  strong {
    color: $button-border;
  }

  p {
    margin-bottom: 0.5rem !important;
  }
}

.content {
  ul.notification {
    margin-left: 0;
  }
}

.button {
  border-radius: 3px;
  height: auto;
  box-shadow: $shadow;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 300ms ease;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  font-size: 0.9rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  stroke: white;

  svg {
    margin-right: 0.5rem;
  }

  &.is-hovered,
  &:hover {
    color: $button-text-hover-color;
    border-color: $button-border;
    background-color: white;
    stroke: $button-text-hover-color;
  }

  &.is-active,
  &:active {
    box-shadow: $shadow-large;
  }

  @each $name,
  $pair in $colors {
    //$color: nth($pair, 1);
    //$color-invert: nth($pair, 2);
    background-color: $grey-darker;
    border-color: $button-border;

    &.is-#{$name} {

      &.is-hovered,
      &:hover {
        background-color: white;
      }

      &.is-active,
      &:active {
        box-shadow: $shadow-large;
        color: $button-text-hover-color;
      }
    }
  }

  &.is-primary {
    background-color: $grey-darker;
    border-color: $button-border;

    &:hover {
      color: $button-text-hover-color;
    }

    &:disabled {
      background-color: $grey-darker;
      border-color: $button-border-color-disabled;

      &:hover {
        color: $button-color;
      }
    }
  }

  &:disabled {
    background-color: $grey-darker;
    border-color: $button-border-color-disabled;

    &:hover {
      color: $button-color;
    }
  }

  &.is-text {
    box-shadow: none;
    text-decoration: none;
    color: $orange;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    &:hover {
      color: $button-text-hover-color;

      &:disabled {
        color: $link;
      }
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:before {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }

  &.is-success {
    border-color: $success-border;
    background-color: $success;

    &.is-hovered,
    &:hover {
      color: $grey-darkest !important;
    }
  }

  &.is-danger {
    border-color: $error-border-color;
    background-color: $error-color;

    &.is-hovered,
    &:hover {
      color: $grey-darkest !important;
    }
  }

  &.is-transparent {
    background-color: transparent;

    &.is-hovered,
    &:hover {
      background-color: white;
    }
  }

  &.is-add {
    margin-left: 1rem;
    margin-top: 1rem;

    &.is-hovered,
    &:hover {
      #Icon_Plus {
        stroke: $grey-darkest;
      }
    }

    &:focus {
      #Icon_Plus {
        stroke: $green;
      }
    }
  }

  &.arrow {
    padding: 5px;
    margin-top: 0px;
    float: right;
    position: relative;
    right: -9px;
    top: -2px;

    &:hover {
      * {
        stroke: $grey-darkest !important;
      }
    }

    &.is-focused,
    &:focus {
      border-color: $button-border !important;
      border-width: 1px !important;
      border-style: solid !important;
      box-shadow: none !important;
      outline-color: transparent;
    }

    .left-arrow {
      //transition: transform 0.5s ease;
      margin-right: 0;

      &.minimized {
        transform: rotate(180deg);
      }
    }

    &:focus {
      border: white;
    }
  }

  &.is-on-white {

    &.is-hovered,
    &:hover {
      background-color: $purple;
      color: white;
    }
  }

  &.is-small {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.8rem;
  }
}

.input {
  background: none;
  border: none;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  height: 2.4rem;
  box-shadow: inset 0 -1px 0 $control-border;
  transition: all 300ms;

  &.is-small {
    border-radius: 0;
  }

  &.is-active,
  &.is-focused,
  &:active,
  &:focus {
    box-shadow: inset 0 -2px 0 $input-focus-border-color;
  }

  @each $name,
  $pair in $colors {
    $color: nth($pair, 1);

    &.is-#{$name} {
      box-shadow: inset 0 -1px 0 $color;

      &.is-active,
      &.is-focused,
      &:active,
      &:focus {
        box-shadow: inset 0 -2px 0 $color;
      }
    }
  }

  &.is-disabled,
  &[disabled],
  &[readonly] {
    border-bottom: 1px solid $disabled-text;
    cursor: not-allowed;
  }
}

input[type="checkbox"]:disabled+label:before {
  background-color: black;
}

.label {
  &.is-disabled {
    color: $disabled-text;
  }
}

.is-disabled {
  color: $disabled-text;
}

.textarea:not([rows]) {
  border: none;
  border-radius: 0;
  background: none;
  box-shadow: none;
  box-shadow: inset 0 -1px 0 $control-border;
  padding-left: 0;
  min-height: 8em;

  &:focus {
    border: none;
    box-shadow: inset 0 -2px 0 $input-focus-border-color;
  }
}

.select,
select {
  width: 100%;
}

.time select {
  width: 45px;
  font-size: 1em;
  display: inline;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: inset 0 -1px 0 $control-border;
  transition: all 300ms;
  padding-left: 0;
  color: #9d9d9d;

  &.is-active,
  &.is-focused,
  &:active,
  &:focus {
    box-shadow: inset 0 -2px 0 $input-focus-border-color;
  }

  option {
    background: $select-background;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  }
}

.select select {
  background: none;
  border-radius: 0;
  transition: all 300ms;
  padding-left: 0;
  border: none;
  box-shadow: inset 0 -1px 0 $control-border;

  &.is-active,
  &.is-focused,
  &:active,
  &:focus {
    border: none;
    box-shadow: inset 0 -2px 0 $input-focus-border-color;
  }

  &[disabled] {
    box-shadow: inset 0 -1px 0 $disabled-text;
  }

  option {
    background: $select-background;
    color: #fff;
    border: none;
    text-shadow: none;

    &.focus {
      background-color: $orange;
    }
  }
}

.control {
  &.has-addons {

    .button,
    .input,
    .select {

      &:first-child,
      &:last-child {
        border-radius: 0;

        select {
          border-radius: 0;
        }
      }
    }

    .button {
      box-shadow: none;
    }
  }
}

.control.fixed-height {
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: $button-border;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $grey-darkest;
    border-radius: 5px;
  }
}

.progress {
  height: 0.5rem;
  border-radius: 10;
  background-color: $grey-darkest;
  color: $orange;
  width: 30rem;

  &.is-small {
    height: 0.5rem;
  }
}

.progress:indeterminate {
  background-image: linear-gradient(to right, $orange 30%, $grey-darkest 30%);
}

.progress-level {
  margin-bottom: 0.5rem !important;
}

.progess-percentage {
  font-size: 0.7rem;
}

.progress-complete {}

.progress-failed {}

.card {
  box-shadow: $shadow;

  .card-header {
    box-shadow: none;
  }

  .card-footer,
  .card-footer-item {
    border: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.menu {
  .menu-list {
    li {
      position: relative;

      &:hover .menu-tooltip {
        transition: all 0.5s ease;
        opacity: 1;
        top: 0;
      }
    }

    div {
      padding-left: 1rem;
    }

    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #ffffff;
      border-radius: 0;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 1rem;
      stroke: #495d7a;
      transition: background-color 300ms linear, color 100ms linear, stroke 200ms linear;

      &.is-active {
        background-color: $purple;
        color: $purple-invert;

        .menu-icon {
          stroke: #040f21;
        }
      }

      &.no-ellipsis {
        text-overflow: clip !important;
      }
    }

    a:hover {
      background-color: $purple;
      color: $purple-invert;

      .menu-icon {
        stroke: #040f21;
      }
    }

    .menu-tooltip {
      display: none;
      color: $grey-darkest;
      white-space: nowrap;
      padding-left: 1rem;
      padding-right: 1rem;

      &.minimized {
        position: absolute;
        left: 65px;
        top: -25px;
        // transform: translate(-50% , -50%);
        border-radius: 3px;
        height: 35px;
        background: #fff;
        line-height: 35px;
        text-align: center;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        transition: 0s;
        opacity: 0;
        pointer-events: none;
        display: block;
      }
    }
  }

  hr {
    margin-bottom: 0.5rem;
  }

  .menu-label {
    color: $title-color;
    text-transform: none;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 1rem;

    &.left-menu {
      text-overflow: clip;
      white-space: nowrap;
      transition: all 0.5s ease;

      &.minimized {
        padding-left: 5px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.2rem;
    }
  }

  .sidebar ul li .tooltip {
    position: absolute;
    left: 125px;
    top: 0;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    height: 35px;
    width: 120px;
    background: #fff;
    line-height: 35px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0s;
    opacity: 0;
    pointer-events: none;
    display: block;
  }

  .sidebar.active ul li .tooltip {
    display: none;
  }

  .sidebar ul li:hover .tooltip {
    transition: all 0.5s ease;
    opacity: 1;
    top: 50%;
  }
}

.menu-icon {
  margin-right: 0.5rem;
  width: 1.5rem;
  vertical-align: middle;
  stroke: #495d7a;
  transform: scale(0.9);
}

.menu-label {
  color: $title-color;
  text-transform: none;
  font-size: 1rem;
  font-weight: bold;
}

.notification {
  box-shadow: $shadow;

  @each $name,
  $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }

  background-color: #173146;
  border-color: #63b7dd;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;

  &.is-danger {
    border-color: #f75a65;
  }

  &.is-warning {
    border-color: #f9e26e;
  }
}

.modal {
  .modal-background {
    background-color: rgba($black, 0.6);
  }

  .modal-card {
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
      0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  }
}

.navbar {
  background-color: #222e44;



  &:not(.is-transparent) {
    box-shadow: $shadow;
  }

  .has-dropdown .navbar-item {
    @include desktop {
      color: $text;
    }
  }

  @include touch {
    .navbar-menu {
      background-color: inherit;
    }
  }

  @each $name,
  $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      @include touch {
        .navbar-menu {

          .navbar-item,
          .navbar-link {
            color: $color-invert;

            &.is-active {
              background-color: $purple;
            }
          }
        }
      }
    }
  }

  .navbar-item {
    &.is-active {
      background-color: $purple;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.tabs {
  .is-active a {
    box-shadow: inset 0 -1px 0 $link;
  }

  &.is-boxed {
    .is-active a {
      border-top: 0;
      box-shadow: inset 0 2px 0 $link;
    }
  }
}

.panel {
  box-shadow: $shadow;

  .panel-block,
  .panel-heading,
  .panel-tabs {
    border-radius: $radius;
    border: none;
    padding: $size-7;
  }

  .panel-block.is-active {
    color: $primary;
  }

  .panel-tabs {
    a:hover {
      border-color: $link-hover;
      color: $link-hover;
    }
  }
}

.field:not(:last-child) {
  margin-bottom: 1.75rem;
}

.checkbox {
  margin-right: 1rem;

  span {
    margin-left: 0.3rem;
  }

  input[type="checkbox"] {
    margin-right: 0.3rem;
  }
}

.radio-list {
  .radio {
    display: block;

    &+.radio {
      margin-left: 0;
      margin-top: 0.5em;
    }
  }
}

.time-container {
  display: flex;

  .time-width {
    display: flex;
    width: 140px;
  }

  .plus-day-button {
    height: 1.5rem;
    margin-left: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    align-self: last baseline;
  }
}

.time-seperator {
  margin-left: 3px;
  margin-right: 5px;
}

.page-whole-number-width {
  width: 55px;
}

.page-eigtht-width {
  width: 65px;
}

.eighth {
  font-size: 0.7rem;
}

.small-text {
  font-size: 0.7rem;
}

/* Hide arrows from input type number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.circular-progress {
  text-align: center;
  max-width: 250px;

  &.overview {
    margin-right: 0.85rem;
    margin-bottom: 0.6rem;
  }
}

.CircularProgressbar {
  padding: 1rem;
}

.react-select-control {
  width: 100%;

  .react-select__multi-value__remove {

    &.is-hovered,
    &:hover {
      color: $orange;
      border-color: transparent;
      cursor: pointer;
      background-color: transparent;
    }
  }

  .react-select__clear-indicator {
    background-color: transparent;
    color: white;

    &.is-hovered,
    &:hover {
      color: $orange;
      cursor: pointer;
      border-color: transparent;
      //background-color: $background;
    }
  }

  .react-select__dropdown-indicator {
    background-color: transparent;
    color: white;
    padding: 0;

    &.is-hovered,
    &:hover {
      //background-color: $background;
      color: $orange;
      cursor: pointer;
    }
  }

  .react-select__control {
    background: transparent;
    color: $text;

    border: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    //border-bottom: 1px solid $control-border;
    //transition: all 300ms;
  }

  /*
  &:focus {
    border-bottom: 2px solid $green;
  }
*/
  .react-select__placeholder {
    color: $placeholder-color;
    margin-left: 0;
    padding-left: 0;
  }

  .react-select__menu {
    z-index: 10;
    border: none;
    border-radius: 0px;
    background: $select-background;
    color: $text;

    .react-select__menu-list {
      .react-select__option {
        background-color: $select-background;

        &:hover {
          background-color: $orange;
          color: $purple-invert;
        }
      }

      .react-select__option--is-selected {
        background-color: $purple;
        color: $purple-invert;
      }
    }
  }

  .react-select__value-container {
    background-color: transparent;
  }

  .react-select__multi-value,
  .react-select__multi-value__label {
    background: $select-background;
    color: $text;
  }
}

select {
  display: none;
  /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

select option {
  color: white !important;
  box-shadow: none;
  border: none;
}

select option,
select optgroup {
  background-color: $select-background !important;

  &:hover {
    background-color: $orange !important;
  }
}

.modal-card-body {
  background-color: $grey-darker;
  color: white;
}

.container {
  margin-bottom: 20px;
}

.delete-icon {
  top: 4px;
  position: relative;
}

.material-icons {
  position: relative;
  margin-right: 0rem;
  vertical-align: bottom;

  &.small {
    font-size: 18px;
  }

  &.has-text-on-right {
    margin-right: 0.5rem;
  }

  &.dark {
    color: $button-border;
  }

  &.drag-icon {
    margin-left: -6px;
  }
}

.disabled {
  cursor: not-allowed;
}

.input-range__track--background {
  background-color: $grey-darkest;
}

.input-range__label {
  font-family: "roboto";
}

.input-range__track--active {
  background-color: $purple;
}

.input-range__slider {
  background-color: $purple;
  border-color: $purple;
}

.orange {
  color: $orange;
}

.mt-05 {
  margin-top: 0.5rem;
}

.pricing-table .pricing-plan .plan-header {
  background-color: $grey-light;
  color: $grey-dark;
}

.pricing-table .pricing-plan {
  border: none;
  background-color: $grey-darkest;
  width: 200px;
}

.pricing-table .pricing-plan .plan-price {
  background-color: $grey-darkest;
}

.pricing-table .pricing-plan.is-active .plan-price .plan-price-amount {
  color: white;
}

.navbar-dropdown {
  right: 0px;
  left: auto;

  &.is-boxed::before {
    content: "";
    height: 0;
    width: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    position: absolute;
    top: -6px;
    right: 26px;
    margin: 0 0 0 -3px;
    z-index: 100;
  }
}

.navbar-divider {
  margin-left: 1em;
  margin-right: 1em;
  padding-top: 0em;
  margin-top: 0em;
}

.navbar-dropdown a.navbar-item {
  padding-right: 1rem;

  &:hover {
    color: $purple !important;
  }
}

.navbar-brand {
  margin-left: 1rem;
  margin-top: -1.3rem;
}

.section {
  padding: 0px;
}

.film-title {
  color: white;
  margin-right: 1rem;
}

th {
  font-weight: normal;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: $success;
  border: 1px solid $success-border;
  border-radius: 5px;
}

.Toastify__toast--warning {
  background: $warning;
  border: 1px solid $warning-border;
  border-radius: 5px;
}

.Toastify__toast--error {
  background: $error-color;
  border: 1px solid $error-border-color;
  border-radius: 5px;
}

.is-size-9 {
  font-size: $size-9;
}

.modern-radio-container {
  margin: 10px 0;
  display: flex;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;

  .radio-outer-circle {
    align-items: center;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 1px solid $button-border;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.1s linear;
    margin-top: 3px;
  }

  .radio-inner-circle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #00ffce;
    transition: all 0.1s linear;

    &.disabled {
      opacity: 50%;
    }
  }

  .unselected {
    border: 1px solid $button-border;
  }

  .unselected-circle {
    width: 0;
    height: 0;
  }

  .helper-text {
    vertical-align: middle;
    color: white;
    padding-right: 8px;
  }
}

span[data-tool-tip] {
  position: relative;
}

span[data-tool-tip]::after {
  content: attr(data-tool-tip);
  display: block;
  position: absolute;
  background-color: $info-background-color;
  padding: 1rem 1rem;
  color: white;
  border-radius: 5px;
  font-size: 0.8rem;
  bottom: 0;
  left: 0;
  font-family: $family-primary;
  white-space: pre-line;
  //white-space: nowrap;
  //max-width: 10rem;
  min-width: 20rem;
  display: flex;
  transform: scale(0);
  transition: transform ease-out 150ms, bottom ease-out 150ms;
}

span[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 100%;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}

.table-heading {
  background-color: $purple;
  color: $purple-invert !important;
}

.table tbody tr:last-child td {
  border-bottom-width: 1px;
}

.navbar-dropdown {
  .navbar-item {
    color: $grey-darkest !important;

    &.mobile-menu {
      color: white !important;
    }

    &:hover {
      color: $link;
    }
  }
}

.user-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  color: $grey-darkest;
  font-size: 1.2rem;
  background-color: $green;
}

.user-details-container {
  display: flex;
}

.user-details-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  background-color: $title;
  color: $grey-darkest;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.navbar-item {
  &.user-details {
    color: $grey-darkest !important;
    display: flex;

    .user-details-email-address {
      display: block;
      color: $title !important;
      font-size: 0.8rem;
    }
  }

  &.notification-details {
    margin-bottom: 0.3rem;

    .dismiss-all-button {
      margin-left: 2rem;
    }
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
}

.bell-icon {
  stroke: white;
}

.user-notification-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.2rem;

  &.top {
    margin-left: 0.5em;
  }

  &.warning {
    background-color: $warning-border;
  }

  &.error {
    background-color: $error-border-color;
  }

  &.info {
    background-color: $info-border-color;
  }
}

.notifications-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
}

.navbar-notification {
  display: block;
  margin-left: 0rem;

  .column {
    padding-left: 0;
    padding-right: 0.8rem;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 0em;
  }

  .notification-data {
    min-width: 20rem;
    max-width: 20rem;
    white-space: normal;
  }

  &.date {
    color: $title !important;
  }
}

.navbar-dropdown.is-boxed {
  .columns:not(:last-child) {
    margin-bottom: 0em !important;
  }
}

.navigation-icon {
  stroke: $grey-darker;

  &.info {
    transform: rotate(180deg);
  }
}

.small-icon {
  stroke: white;
  margin-left: 0rem;
}

.character-progress {
  -webkit-box-ordinal-group: 2;
  order: 2;
  margin-top: 5px;
  margin-left: 10px;
  width: 5rem !important;
}

.input-in-select {
  border: none;
  background-color: transparent;
  box-shadow: none;
  background: none;
  border: none;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  color: white;

  &:focus {
    box-shadow: none;
  }
}

.tag {
  font-weight: 600;

  &.is-purple {
    color: $grey-darkest;
    background-color: $purple;
  }

  &.is-danger {
    color: $grey-darkest !important;
    background-color: $error-border-color !important;
  }
}

.tag:not(body) {
  height: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.white-icon {
  stroke: white;
  position: relative;
  bottom: 1px;
  margin-right: 0.5rem;
}

.checkbox-items-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: $button-border;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $grey-darkest;
    border-radius: 5px;
  }
}

.checkbox-items-container>span {
  flex: 0 1 250px;
}

.onboarding-tooltip {
  width: 380px;
  background-color: #fff;
  border-radius: 4px;
  color: $dark-text;


  &__body {
    padding: 20px 20px 40px;
  }

  &__content {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.875rem;

    p {
      margin-top: 1em;
      margin-bottom: 1em;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-top: 0;
      }
    }
  }

  &__close {
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
    margin-bottom: 12px;
    margin-left: auto;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 0;
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 0;
      background-color: #627795;
    }

    &:before {
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  &__header {
    margin-bottom: 0.5em;
    font-size: 1.25rem;
    font-weight: bold;
  }

  &__actions {
    border-top: 1px solid rgba(#c5d0e0, 0.5);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
      opacity: 1;
      font-family: inherit;
      color: #627795;
      transition: background-color 0.25s, color 0.25s, opacity 0.25s;
    }

    .onboarding-tooltip {
      &__back-btn {
        padding: 0.5em 1em;

        &[disabled] {
          opacity: 0;
          pointer-events: none;
        }

        &:hover {
          color: $grey-darkest;
        }
      }

      &__next-btn {
        min-width: 94px;
        background-color: $green;
        border-radius: 4px;
        padding: 0.5em 1em;
        color: $grey-darkest;

        &:hover {
          background-color: darken($green, 2);
        }
      }
    }
  }

  &__steps {
    display: flex;

    button {
      padding: 3px;
      font-size: 0;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        display: block;
        background-color: rgba(#c5d0e0, 0.5);
        border-radius: 50%;
        transform: scale(0.8);
        transition: transform 0.25s, background-color 0.25s;
      }

      &.active,
      &:hover {
        &:before {
          background-color: $green;
          transform: scale(1);
        }
      }
    }
  }
}

.__floater.__floater__open {
  transition: none !important;
}

.help-panel {
  width: 455px;
  max-width: 100%;
  position: fixed;
  top: 5rem;
  bottom: 0;
  right: 0;
  z-index: 29;
  transform: translateX(100%);
  opacity: 0;
  display: flex;
  flex-direction: column;
  transition: transform 0.25s, opacity 0.25s;
  pointer-events: none;
  background-color: #fff;
  color: $dark-text;
  font-size: 0.9rem;

  &.active {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
  }

  &.sub-menu-active {
    .help-panel__header-left {

      button,
      h2 {
        transform: translateX(0);
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid #c5d0e0;
  }

  &__header-left {
    display: flex;

    button,
    h2 {
      transform: translateX(-36px);
      transition: transform 0.25s, opacity 0.25s;
    }

    button {
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      margin-right: 20px;
      cursor: pointer;
      opacity: 0;
      pointer-events: none;
      position: relative;

      &:hover {
        &:after {
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }
      }

      &:after {
        content: "";
        width: 32px;
        height: 32px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.8);
        background-color: rgba(#c5d0e0, 0.5);
        border-radius: 50%;
        pointer-events: none;
        opacity: 0;
        transition: transform 0.25s, opacity 0.25s;
      }

      svg {
        width: 16px;
        fill: #627795;
        display: block;
        position: relative;
        z-index: 1;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      color: $dark-text;
    }
  }

  &__close {
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
    margin-left: auto;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 0;
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 0;
      background-color: #627795;
    }

    &:before {
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  &__body-wrap {
    flex: 1;
    position: relative;
  }

  &__body {
    padding: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }

  .react-select-control {
    .react-select__clear-indicator {
      color: $dark-text;

      &.is-hovered,
      &:hover {
        color: $orange;
      }
    }

    .react-select__dropdown-indicator {
      color: $dark-text;

      &.is-hovered,
      &:hover {
        color: $orange;
      }
    }

    .react-select__single-value {
      color: $dark-text;
    }

    .react-select__input {
      color: $dark-text;
    }
  }

  .control {
    font-size: 0.9rem;
  }

  .label,
  .input,
  .textarea,
  .select select {
    color: $dark-text;
    font-size: inherit;
  }
}

.help-panel-bg-close {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 28;
  background-color: rgba(#040f21, 0.5);
  opacity: 0;
  border: none;
  padding: 0;
  margin: 0;
  transition: left 0s 0.25s, opacity 0.25s;
  cursor: pointer;

  &.active {
    left: 0;
    opacity: 1;
    transition: opacity 0.25s;
  }
}

.help-panel-sub-menu {
  padding: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  transform: translateX(5rem);
  opacity: 0;
  transition: transform 0.25s, opacity 0.25s;
  pointer-events: none;
  background-color: #fff;

  &.active {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c5d0e0;
    padding-bottom: 30px;
    margin-bottom: 30px;

    h3 {
      font-size: 20px;
      font-weight: bold;
      color: $dark-text;
    }
  }

  &__icon {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: $purple;
    border-radius: 50%;
    margin-right: 12px;
  }
}

.help-panel-button {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  >a,
  >button {
    width: 100%;
    padding: 16px;
    margin: 0;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #e4ecf8;
    font-size: 18px;
    font-family: inherit;
    font-weight: bold;
    text-align: inherit;
    line-height: inherit;
    color: $dark-text;
  }

  >button {
    cursor: pointer;
  }

  &__icon {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: $purple;
    border-radius: 50%;
    margin-right: 12px;

    svg {
      fill: #fff;
    }
  }
}

.help-panel-faq {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #c5d0e0;

  &.active {
    .help-panel-faq {
      &__question-icon {
        &:after {
          height: 0;
        }
      }

      &__answer {
        height: auto;
      }
    }
  }

  &__question {
    width: 100%;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: inherit;
    line-height: inherit;
    font-size: 16px;
    font-weight: bold;
    color: $dark-text;
    cursor: pointer;
  }

  &__question-icon {
    width: 12px;
    height: 12px;
    position: relative;
    flex-shrink: 0;
    margin-left: 12px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #627795;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 100%;
      height: 2px;
    }

    &:after {
      width: 2px;
      height: 100%;
      transition: height 0.25s;
    }
  }

  &__answer {
    height: 0;
    overflow: hidden;
    color: #495d7a;
  }
}

.leave-feedback-requests {
  margin-top: 30px;

  h3 {
    font-weight: bold;
  }

  >div {
    margin-top: 10px;
  }
}

.navbar-padding {
  padding-top: 50px;
}

.link-on-white {
  color: $purple
}

.field-body {
  &.dietary-requirements {
    display: block;
  }
}

.is-clickable {
  cursor: pointer !important
}

.recaptcha-info {
  font-size: 0.8rem;
  color: rgb(98, 119, 149);

  a {
    color: rgb(73, 93, 122);
  }
}

.cke_notifications_area {
  visibility: hidden !important;
}