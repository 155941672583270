@import "./../variables";

.marketing-timeline-container {
  margin-top: 2rem;
  padding-top: 4rem;
  padding-bottom: 3rem;
  background-color: rgb(244, 244, 244);
}

.marketing-timeline-box {  
  margin-top: 0;
  margin-left: 15rem;
  margin-right: 15rem;
  border-radius: 30px;
}

@media only screen and (max-width: 864px) {
  .marketing-timeline-box {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.marketing-timeline-content {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0;
  padding-top: 0rem;
  padding-bottom: 1.5rem;
  color: #222e44;
  font-size: 0.9rem;
  text-align: center;
}

.marketing-timeline {
  clear: both;
  margin-bottom: 0rem;
  margin-left: 1rem;
  margin-right: 1rem;
  transition: max-height 0.8s ease-in-out;
  overflow: hidden;
}

.marketing-timeline-half {
  max-height: 34.5rem;
}

.marketing-timeline-all {
  max-height: 81rem;
}

.timeline-content {
  color: #222e44;
  padding-top: 1rem !important;
  opacity: 0;
  transition: opacity 250ms;

  &.first-timeline-content {
    padding-top: 3rem !important;
  }

  &.last-timeline-content {
    padding-bottom: 2rem !important;
  }

  &.timeline-item-in-view {
    opacity: 1;
  }
}

.timeline-item {
  padding-bottom: 1rem !important;
}

.timeline-item:last-of-type {
  padding-bottom: 1rem !important;
}

.timeline-marker {
  background-color: $title;
  &.first-timeline-item {
    margin-top: 2rem;
    // background-color: $orange !important;
    // border-color: $orange !important;
  }

  &.completed-timeline-item {    
    background-color: $orange !important;
    border-color: $orange !important;
  }
}

.timeline-tag {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}

.timeline-heading {
  font-size: 0.9rem !important;
}

.timeline-description {
  font-size: 0.8rem;
  max-width: 20rem;
}

.marketing-timeline-button-conatiner {
  display: flex;
  align-items: center;
}

.timeline-button {
  width: 10rem;
  background-color: white;
  border-color: $green;
  border-width: 2px;
  color: #222e44;
  margin-left: auto;
  margin-right: auto;
}
