@import "../variables";

.script-formats {
  margin-top: 0.5rem;

  .tag {
    background-color: $purple;
    color: $purple-invert;
  }
}

.file.is-boxed.has-name .file-selector-button {
  border-radius: 4px;
}

.script-progress {
  width: 23rem !important;
}

.supported-formats {
  font-size: 0.9rem !important;
}