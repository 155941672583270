.change-log-change {
    margin-bottom: 1.2rem;
    display: flex;
}

.change-log-headline {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
}

.change-log-date {
    font-size: 0.8rem;
    margin-top: 0rem;
}

.change-log-date-container {
    display: flex;
    justify-content: right;
}

.change-log-icon {
    margin-right: 1rem;
    min-width: 30px;
    width: 30px;
    height: 30px;
    position: relative;
    top: 3px;
}

.change-log-change-text {
    flex-shrink: 1;

    p {
        margin-bottom: 1rem;
    }
}

.change-log-change-header-tag {
    width: 40px;
    height: 40px;
    padding-left: 1rem;
    margin-top: 6px;
    margin-right: 1rem;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        position: relative;
        top: 0px;
    }
}

.change-log-feature-request-container {
    font-size: 0.9rem;

    .change-log-feature-request {
        color: #627795
    }
}