.shooting-day-actors {
    .add-actor-container {
        display: flex;

        .add-actor-list {
            max-width: 20rem;
        }

        .add-actor-time {
            font-weight: bold;
            white-space: nowrap;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-top: 0.5rem;
            vertical-align: middle;
        }

        .add-actor-button {
            margin-top: 0.3rem;
            max-height: 2rem;
        }
    }
}