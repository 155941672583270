@import "./../variables";

.marketing-prices-t-and-c-box {  
  margin-top: 0;
  margin-left: 5rem;
  margin-right: 5rem;
  border-radius: 30px;
}

.marketing-prices-t-and-c-content {
  margin-left: 4rem;
  margin-right: 4rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: white;
  font-size: 0.9rem;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .marketing-prices-t-and-c-box {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
