@import "../../variables";

.tasks {

    display: flex;

    .tasks-column {
        display: block;
        padding: 1rem;
        padding-top: 0.5rem;
        width: 20rem;
        min-width: 20rem;
        max-width: 20rem;
        margin: 8px;
        //border: 1px solid lightgrey;
        background-color: $background-color;
        border-radius: 5px;

        .title {
            padding: 8px;
        }
    }

    .task-list {
        height: 100%;
    }

    .task {
        //border: 1px solid lightgrey;

        border-radius: 5px;
        padding: 8px;
        background-color: $dark-text; // $grey-darker;
        margin-bottom: 8px;
        transition: background-color 300ms linear;

        &.dragging {
            color: $grey-darkest;
            background-color: $purple;
        }

        &.saving {
            background-color: $grey-darker;
        }

        &.is-danger {
            background-color: #442c3e
        }

        .task-content-container {
            display: flex;

            .task-content {
                margin-right: 0.7rem;
                flex-grow: 1;
                overflow: hidden;
                text-overflow: ellipsis;


                .task-text {
                    overflow: hidden;
                }
            }

            .task-content-button {
                flex-shrink: 1;
                max-width: 4rem;
                align-items: center;

                .button {
                    max-width: 2rem;
                    min-width: 2rem;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    padding-top: 0;
                }
            }

            a.dropdown-item {
                padding-right: 1rem;
            }

            .dropdown-item {
                display: flex;
                align-items: center;
            }

            .dropdown-content {
                box-shadow: 0 0.5em 1em 0.125em rgba(20, 20, 20, 0.5), 0 0px 0 1px rgba(10, 10, 10, 0.02);
            }


            .dropdown-trigger {
                .dropdown-content {
                    display: none;
                    position: absolute;
                    top: 4.5rem;
                    margin-left: 100px;
                }

                /* Links inside the dropdown */
                .dropdown-content a {
                    text-decoration: none;
                    display: block;
                }

                /* Change color of dropdown links on hover */


                /* Show the dropdown menu on hover */
                &:hover .dropdown-content {
                    display: block;
                }

                /* Change the background color of the dropdown button when the dropdown content is shown */


            }
        }

        .assignee-circle {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            height: 2rem;
            width: 2rem;
            background-color: $orange;
            color: $grey-darkest;
            font-size: 0.9rem;
            margin-top: 0.5rem;
        }
    }
}

@media only screen and (max-width: 768px) {
    .tasks {
        flex-wrap: wrap;
    }
}