.actor-overview-row {
    display: flex;
}

.actor-overview-image {
    margin-top: 0.3rem;
    border-radius: 4px;
}

.actor-overview-loader {
    justify-content: center;
}