@import "./../variables";

html {
  scroll-behavior: smooth;
}

.marketing-background {
  background-color: white;
}

section {
  scroll-margin-top: 4rem;
}

.script-mark {
  background-color: $green;
  border-radius: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.call-sheet-mark {
  background-color: $purple;
  border-radius: 8px;
  padding-left: 5px;
  padding-right: 5px;

}

.marketing-container {
  //margin-top: 80px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 2100px) {
  .marketing-container {
    //overflow: visible;
  }
}

.hollow-button {
  background-color: transparent;
  border-color: $green !important;
  color: $grey-darkest;
  transition: background-color 250ms ease-in;

  &:hover {
    background-color: $green;
    color: $grey-darkest !important;
  }

  &:focus {
    color: $title;
  }
}

.marketing {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  background-image: url("/images/big-f.svg");
  background-repeat: no-repeat;

  background-position: right -150px top -120px;
  //background-attachment: fixed;
  position: relative;
  color: $dark-text;

  .button {
    border-radius: 8px;
  }

  .logo {
    margin-top: 5rem;
    margin-left: 5rem;
    opacity: 1;
    transition: opacity 250ms ease-in;

    &.invisible {
      opacity: 0;
    }
  }

  .marketing-title-container {
    padding-left: 6rem;
  }

  .call-to-action-columns {
    display: flex;
    flex-direction: row;
  }

  .marketing-title-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 0;
    flex-grow: 0;
    flex-shrink: 1;
  }

  .marketing-image-column {
    //position: relative;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .call-to-action {
    padding-top: 10rem;
  }

  h1 {
    min-width: 14ch;
    font-size: clamp(1.8rem, 3vw, 2.5rem);
    font-weight: bold;
    max-width: 20ch;
  }

  .view-feature-container-container {
    margin-left: 6rem;
    margin-top: 1rem;
    display: flex;
    justify-content: left;
  }

  .view-feature-container {
    //margin: 0 auto;
  }

  .view-feature-link {
    color: $dark-text;
  }

  .view-feature {
    // text-align: center;
    // margin: 0 auto;
    margin-bottom: 0.8rem;
  }

  .view-feature:hover+.view-feature-image {
    transform: translateY(5px);
  }

  .view-feature-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
    transition: transform 250ms;

    &:hover {
      transform: translateY(5px);
    }
  }

  .marketing-titles {
    color: #222e44;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    text-align: center;
  }

  .marketing-button {
    color: $grey-darkest !important;

    background-color: $purple;
    border-color: transparent;

    &:hover {
      background-color: $purple-invert;
      color: white !important;
    }
  }

  .marketing-login-button {
    color: $grey-darkest !important;
    margin-left: 1rem;
    background-color: $green;
    border-color: transparent;

    &:hover {
      background-color: $purple-invert;
      color: white !important;
    }
  }

  .marketing-image {
    position: absolute;
    max-height: 400px;
    right: -100px;
  }

  .rh5v-Overlay_inner {
    display: none;
  }

  .rh5v-Seek_fill {
    background-color: $green;
  }

  .rh5v-Volume_fill {
    background-color: $green;
  }

  .video-poster {
    width: 100%;
    cursor: pointer;
  }

  .video-container {
    // background-color: $purple;
    max-width: 1000px;
    //margin-right: 2rem;
    //margin-bottom: 2rem;
    margin: 0 auto;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .video {
    // background-color: $purple;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    line-height: 0;
  }

  .marketing-bottom-section {
    background-color: $background-color;
    padding-bottom: 5rem;
  }

  .marketing-copyright {
    color: white;
    text-align: center;
  }

  @media only screen and (min-width: 1280px) {
    .marketing-image {
      right: 30px;
    }
  }

  @media only screen and (max-width: 936px) {

    background-size: 530px;
    background-position: right -40px top -80px;

    .call-to-action {
      padding-top: 5rem;
    }

    .call-to-action-columns {
      margin-top: 30px;
      flex-direction: column;
    }

    .video-container {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    h1 {
      text-align: center;
      margin: 0 auto;
      font-size: 2rem;
    }

    .marketing-image-column {
      margin-top: 2rem;
    }

    .marketing-title-container {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .view-feature-container-container {
      margin-top: 2rem;
      margin-left: 0;
      justify-content: center;
    }


  }

  @media only screen and (max-width: 768px) {
    background-size: 200px;
    background-position: right -30px top 390px;
  }

  @media only screen and (max-width: 768px) {

    .logo {
      margin-top: 5rem;
      margin-left: 3rem;
    }

    .marketing-image {
      position: relative;
      max-height: 400px;
      right: auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .marketing-navbar-brand {
    margin-top: -10px !important;
  }
}