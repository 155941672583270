@import '../../../variables';

.whats-new-container {
    background: linear-gradient(#C5D0E0, #C5D0E0) no-repeat left/1px 100%;
    background-position: left 23px bottom 60px;
}


.change-log-change-help {
    margin-bottom: 1.2rem;
    //display: flex;
}

.change-log-change-help-item-header {
    display: flex;
    flex-grow: 1;    
}

.change-log-change-help-item-header-tag {
    display: flex;
    border-radius: 4px;
    padding: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #040F21;
}

.change-log-headline {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
}

.change-log-date-help {
    font-size: 0.8rem;
    margin-top: 6px;        
}

.change-log-date-container-help {
    display: flex;    
    flex-grow: 1;
    justify-content: right;
}

.change-log-icon-help {    
    margin-right: 6px;
    min-width: 20px;
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;    
    fill: #222e44;
}

.change-log-change-text-help {
    margin-top: 1rem;
    margin-left: 2rem;
    flex-shrink: 1;
}

.change-log-item {
    margin-bottom: 1rem;
}