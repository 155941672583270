@import '../../../variables';

.feature-request-container {
    margin-bottom: 1.2rem;
    display: flex;
}

.feature-request-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
}

.feature-request-date-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .feature-request-date {
        font-size: 0.8rem;
        margin-top: 0rem;
        color: $title-color
    }

    .feature-request-user-name {
        color: $purple;
        font-size: 0.8rem;
        margin-top: 0rem;
    }
}

.feature-request-status-container {
    margin-top: 1rem;
    font-size: 0.8rem;
    display: flex;

    .feature-request-status {}
}

.feature-request-version-container {
    margin-top: 0.3rem;
    font-size: 0.8rem;
    display: flex;
}

.change-log-icon {
    margin-right: 1rem;
    min-width: 30px;
    width: 30px;
    height: 30px;
    position: relative;
    top: 3px;
}

.feature-request-details {
    flex-grow: 1;
}

.feature-request-section {
    font-size: 0.9rem;
}

.feature-request-text {
    margin-top: 1rem;
    flex-shrink: 1;
}

.feature-request-vote-container {
    display: block;
    flex: none;
    margin-right: 1rem;

    .feature-request-vote-thumbs {
        min-height: 42px;
    }

    .feature-request-up-vote {
        position: relative;
        top: -2px;
    }

    .feature-request-down-vote {
        cursor: pointer;
        position: relative;
        transform: scaleX(-1);
        top: 3px;
    }

    .feature-request-vote-count {
        margin-left: 0.5rem;
    }
}

.feature-request-comment {

    margin: 1rem;

    .feature-request-comment-username {
        font-size: 0.8rem;
        color: $purple;
    }

    .feature-request-comment-date {
        font-size: 0.8rem;
        color: $title-color
    }
}

.feature-request-comment-hr {
    margin-bottom: 1rem;
    height: 1px;
}