@import "../../_variables.scss";

$boxShadow: 0px 0px 24px 6px rgba(black, 0.08);

@function gray($color) {
  @return rgb($color, $color, $color);
}

[contenteditable] {
  outline: 0px solid transparent;
}

.context-menu-item {
  background-color: $grey-darkest;
  border: none;
  color: $text;
  border-radius: 100px;
  box-shadow: $boxShadow;
  height: 50px;
  outline: none;
  padding: 0px;
  position: absolute;
  transition: left 0.25s, top 0.25s, opacity 0.25s;
  width: 50px;

  .menu-icon {
    margin-left: 10px;
    margin-top: -5px;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    z-index: 2;

    h1 {
      opacity: 1;
      right: -10px;
    }

    i {
      color: $orange;
    }

    background-color: $purple;

    .menu-icon {
      stroke: $purple-invert;
    }
  }

  &:active {
    transform: scale(1);
  }

  h1 {
    background-color: gray(40);
    border-radius: 4px;
    color: white;
    font-size: 0.8em;
    font-weight: 700;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    right: 0px;
    text-transform: uppercase;
    top: 50%;
    transform: translate(100%, -50%);
    transition: all 0.25s;
    z-index: 2;
  }

  i {
    font-size: 2.5em;
  }
}

.script-container {
  display: flex;
}

.script {
  display: inline-block;
  scroll-behavior: smooth;
  font-family: 'Courier Prime';
  background-color: white;
  width: 640px;
  height: 1050px;
  overflow-y: scroll;
  padding: 20px;
  color: black;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: $button-border;

    border-radius: 5px;
    cursor: pointer !important;

    &:hover {
      cursor: pointer !important;
    }
  }

  &::-webkit-scrollbar-track {
    cursor: pointer !important;
    background: $grey-darkest;
    border-radius: 5px;
  }

  ::selection {
    color: white;
    background: $grey-darker;
  }

  .action {
    margin-top: 1rem;
  }

  .general {
    margin-top: 1rem;
  }

  .transition {
    margin-top: 1rem;
    margin-right: 2rem;
    text-align: right;
    text-transform: uppercase;
  }

  .character {
    text-transform: uppercase;
    margin-top: 1rem;
    text-align: center;
  }

  .dialogue {
    margin-left: 120px;
    margin-right: 120px;
  }

  .parenthetical {
    margin-left: 160px;
    margin-right: 160px;
  }

  .lyrics {
    margin-top: 1rem;
  }

  .element-style-bold {
    font-weight: bold;
  }

  .element-style-italic {
    font-style: italic;
  }
}

.section-nav {
  height: 1050px;
  width: 250px;
  overflow-y: scroll;
  top: 2rem;
  align-self: start;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: $button-border;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $grey-darkest;
    border-radius: 5px;
  }

  &.download {
    height: auto;
  }
}

.current {
  a {
    background-color: $purple;
    color: $purple-invert !important;
  }
}

.section-nav a {
  transition: all 100ms ease-in-out;
}

.script mark {
  cursor: pointer;
}

mark.characterHighlight {
  background-color: #715ccc;
}

mark.backgroundCharacterHighlight {
  background-color: #ae84ea;
}

mark.propHighlight {
  background-color: #fef3b3;
}

mark.setDressingHighlight {
  background-color: #5cb778;
}

mark.costumeHighlight {
  background-color: #1db5ab;
}

mark.makeupHighlight {
  background-color: #fdabd3;
}

mark.visualEffectHighlight {
  background-color: #ff9800;
}

mark.soundEffectHighlight {
  background-color: #63B7DD;
}

.legend {
  font-family: 'Courier Prime';
  padding: 3px;
  background-color: white;
  margin-bottom: 5px;
  width: 635px;
  overflow: hidden;

  mark {
    margin-right: 10px;
  }
}

.script-edit-mode {
  display: flex;
  margin-bottom: 0.5rem;
}

.split-scene-container-container {
  position: relative;
  width: 0;
  height: 0;

  .split-scene-container {
    position: absolute;
    top: -10px;
    left: -15px;

    span.split-scene {
      font-size: 0.8rem;
    }

    span.split-scene:before {
      display: inline-block;
      content: "";
      height: 1px;
      background: #8c8b8b;
      position: absolute;
      width: 550px;
      top: 50%;
      margin-top: 0px;
      margin-left: 50px;
    }

    hr.split-scene {
      //margin-left: 30px;
      position: relative;
      background-color: #fff;
      border-top: 1px dashed #8c8b8b;
    }
  }
}

.scene-heading-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .scene-heading {
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }

  .join-button-container {
    margin-left: auto;
    justify-content: right;

    .join-button {
      height: 1.5rem;
    }
  }
}

.script mark {
  //transition: all 0.1s linear;
}

// @for $i from 1 through 10 {
//   .script mark:nth-child(#{$i}n) {
//     transition-delay: #{$i * 0.1}s;
//   }
// }

.hide-marks mark {
  background-color: #FFFFFF;
}