@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

@import "variables";

// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/elements/tag.sass";
@import "../node_modules/bulma/sass/elements/progress.sass";
@import "../node_modules/bulma/sass/elements/table.sass";
@import "../node_modules/bulma/sass/components/card.sass";
@import "../node_modules/bulma/sass/elements/notification.sass";
@import "../node_modules/bulma/sass/elements/content.sass";
@import "../node_modules/bulma/sass/elements/box.sass";
@import "../node_modules/bulma/sass/elements/other.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/components/menu.sass";
@import "../node_modules/bulma/sass/components/tabs.sass";
@import "../node_modules/bulma/sass/components/modal.sass";
@import "../node_modules/bulma/sass/components/dropdown.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/components/level.sass";
@import "../node_modules/bulma/sass/grid/columns.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/utilities/derived-variables.sass";
@import "../node_modules/bulma-extensions/bulma-pricingtable/dist/css/bulma-pricingtable.sass";
@import "../node_modules/bulma-extensions/bulma-switch/src/sass/index.sass";
@import "../node_modules/bulma-extensions/bulma-timeline/src/sass/index.sass";

@import "../node_modules/@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css";
@import "../node_modules/react-widgets/dist/css/react-widgets.css";

// TODO: Import this in the component rather than globally
//@import "./films/script/menu.scss";

@import "material-icons/iconfont/material-icons.scss";

@import "overrides";

$sizes: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8
);
$positions: (
  "top",
  "left",
  "bottom",
  "right"
);

@each $size in $sizes {
  @each $position in $positions {
    .m#{str-slice($position, 0, 1)}-#{$size} {
      margin-#{$position}: $size + rem;
    }

    .m#{str-slice($position, 0, 1)}-#{$size}5 {
      margin-#{$position}: ($size + 0.5) + rem;
    }

    .p#{str-slice($position, 0, 1)}-#{$size} {
      padding-#{$position}: $size + rem;
    }

    .p#{str-slice($position, 0, 1)}-#{$size}5 {
      padding-#{$position}: ($size + 0.5) + rem;
    }
  }
}

.blade-container {
  display: flex;
  padding-bottom: 0rem;
  padding-top: 30px;
  height: 100%;
  margin: 0;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $button-border;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $grey-darkest;
    border-radius: 5px;
  }

  .blade-menu {
    min-width: 15rem;
    max-width: 20rem;
    border-right: $background solid 2px;
    background-color: $grey-darkest;
    padding-top: 0.6rem;
    padding-bottom: 1rem;

    &.main {
      position: sticky;
      left: 0;
      z-index: 20;
      transition: all 0.5s ease;

      &.minimized {
        max-width: 55px;
        min-width: 55px;
      }

      .menuText {
        opacity: 1;
        transition: all 0.5s ease;

        &.minimized {
          opacity: 0;
        }
      }
    }
  }

  .blade-content {
    padding: 1rem;
    padding-top: 0.6rem;
    min-width: 400px;
    max-width: 400px;

    &.minimal {
      min-width: 1px;
      max-width: 1px;
      margin-top: -150px;
    }

    &.thin {
      max-width: 200px;
    }

    &.medium-wide {
      max-width: 500px;
    }

    &.wide {
      min-width: 600px;
      max-width: 600px;
    }

    &.wide700 {
      min-width: 700px;
      max-width: 700px;
    }

    &.script-width {
      min-width: 635px;
      max-width: 635px;
    }

    &.wider {
      min-width: 800px;
      max-width: 800px;
    }

    &.charcterOverview {
      min-width: 900px;
      max-width: 900px;
    }

    &.widest {
      min-width: 1000px;
      max-width: 1000px;
    }

    &.wideaf {
      min-width: 1200px;
      max-width: 1200px;
    }
  }

  .required {
    color: $orange;
  }

  .help {
    color: $orange;

    &.is-error {
      color: $error-border-color;
    }
  }
}

.is-error {
  color: $error-border-color;
}

.notification>.info {
  right: 0.5rem;
  position: absolute;
  top: 0.5rem;
}

.pointer {
  cursor: pointer;
}

.menu {
  background-color: $grey-darkest;
}

.is-beta {
  margin-left: 0.5rem;
  position: relative;
  font-weight: normal;
  font-size: 0.6rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  top: 5px;
}

.ckeditor-content {
  all: initial;
  color: white;
  font-family: roboto;

  &.on-white {
    color: #222e44;
  }

  p {
    margin-bottom: 1rem;
  }

  strong {
    font-weight: bold;
  }

  .cke_editable {
    font-size: 13px;
    line-height: 1.6;

    /* Fix for missing scrollbars with RTL texts. (#10488) */
    word-wrap: break-word;
  }

  blockquote {
    font-style: italic;
    font-family: Georgia, Times, "Times New Roman", serif;
    padding: 2px 0;
    border-style: solid;
    border-color: #ccc;
    border-width: 0;
  }

  .cke_contents_ltr blockquote {
    padding-left: 20px;
    padding-right: 8px;
    border-left-width: 5px;
  }

  .cke_contents_rtl blockquote {
    padding-left: 8px;
    padding-right: 20px;
    border-right-width: 5px;
  }

  a {
    color: #0782C1;
  }

  ol,
  ul,
  dl {
    /* IE7: reset rtl list margin. (#7334) */
    *margin-right: 0px;
    /* Preserved spaces for list items with text direction different than the list. (#6249,#8049)*/
    padding: 0 40px;
    list-style-type: initial;
    margin-bottom: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    line-height: 1.2;
  }

  hr {
    border: 0px;
    border-top: 1px solid #ccc;
  }

  img.right {
    border: 1px solid #ccc;
    float: right;
    margin-left: 15px;
    padding: 5px;
  }

  img.left {
    border: 1px solid #ccc;
    float: left;
    margin-right: 15px;
    padding: 5px;
  }

  pre {
    white-space: pre-wrap;
    /* CSS 2.1 */
    word-wrap: break-word;
    /* IE7 */
    -moz-tab-size: 4;
    tab-size: 4;
  }

  .marker {
    background-color: Yellow;
  }

  span[lang] {
    font-style: italic;
  }

  figure {
    text-align: center;
    outline: solid 1px #ccc;
    background: rgba(0, 0, 0, 0.05);
    padding: 10px;
    margin: 10px 20px;
    display: inline-block;
  }

  figure>figcaption {
    text-align: center;
    display: block;
    /* For IE8 */
  }

  a>img {
    padding: 1px;
    margin: 1px;
    border: none;
    outline: 1px solid #0782C1;
  }

  /* Widget Styles */
  .code-featured {
    border: 5px solid red;
  }

  .math-featured {
    padding: 20px;
    box-shadow: 0 0 2px rgba(200, 0, 0, 1);
    background-color: rgba(255, 0, 0, 0.05);
    margin: 10px;
  }

  .image-clean {
    border: 0;
    background: none;
    padding: 0;
  }

  .image-clean>figcaption {
    font-size: .9em;
    text-align: right;
  }

  .image-grayscale {
    background-color: white;
    color: #666;
  }

  .image-grayscale img,
  img.image-grayscale {
    filter: grayscale(100%);
  }

  .embed-240p {
    max-width: 426px;
    max-height: 240px;
    margin: 0 auto;
  }

  .embed-360p {
    max-width: 640px;
    max-height: 360px;
    margin: 0 auto;
  }

  .embed-480p {
    max-width: 854px;
    max-height: 480px;
    margin: 0 auto;
  }

  .embed-720p {
    max-width: 1280px;
    max-height: 720px;
    margin: 0 auto;
  }

  .embed-1080p {
    max-width: 1920px;
    max-height: 1080px;
    margin: 0 auto;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}