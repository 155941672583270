@import "./../../variables";


.account-storage-progress-container {
  display: flex;
}

.account-storage-progress {
  height: 8px;
  float: left;

  flex-grow: 1;
  //border: 2px solid #000;
  border-radius: 4px;
  color: #fff;
}

.account-storage-progress-percentage {
  margin-left: 0.5rem;
  min-width: 1.5rem;
  align-items: flex-end;
  text-align: right;
  justify-content: right !important;
}

.account-remaining-storage {
  margin-left: 1rem;
  color: $title-color;
}

.account-total-storage {
  margin-left: 1rem;
  color: white;
}

.account-level {
  margin-bottom: 0 !important;
}

.account-price-line-through {
  margin-right: 0.5rem;
  text-decoration: line-through;
}

.referral-url {
  font-size: 0.9rem;
}

.account-teacher {
  color: $title-color;
}