@import "./../variables";

.background-f {
  background-image: url("/images/big-f.svg");
  background-repeat: no-repeat;
  background-position: right -350px top 0px;
  background-attachment: fixed;
}

.box {
  &.get-started {
    display: flex;
    background-color: $green;
    margin-top: 1rem;
    margin-right: 5rem;
    //min-height: 14rem;
    max-width: 60rem;
    color: $dark-text;

    > div {
      padding: 30px 0;
      vertical-align: middle;
    }

    a.link {
      color: $purple;
    }
  }

  .get-started-text {
    margin-left: 3rem;
    font-size: 3rem;
    width: 1rem;
    font-weight: bold;
    line-height: 100%;
  }

  .upload-icon {
    stroke: $dark-text;
    margin-right: 0.5rem;
  }
}

.get-started-heading {
  font-weight: bold;
  font-size: 1.3rem;
}

.vl {
  border-left: 1px solid $grey-darkest;
  height: 90px;
  margin-top: 35px;
}

.get-started-sub-heading {
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.container {
  &.film-list {
    margin-top: 6rem;
  }
}

.blade-content {
  &.film-list {
    margin-top: 30px;
  }
}

.film-list {
  h1 {
    font-weight: bold;
  }
}

.button {
  &.get-started {
    color: $dark-text;
    font-size: 0.9rem;
    margin-top: 0.7rem;
  }
}

.card-header-title {
  display: block !important;
  border-radius: 10px;
}

.card {
  min-height: 20rem;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  margin: 10px;
  border-radius: 10px;
  color: $title !important;
}

.card-footer {
  position: absolute;
  bottom: 1.2rem;
  text-transform: none !important;
  width: 93%;
}

.manage-film-button {
  width: 100%;
  font-size: 0.9rem !important;
}

.film-name {  
  overflow: hidden;
  text-overflow: ellipsis;
}