@import "./../variables";

.lozengers {
  margin-top: 2rem;
}

.lozenger {
  margin-top: 5rem;
  border-radius: 72px;
  max-width: 70rem;
  min-height: 25rem;
  margin-left: -5rem;
  float: left;
  overflow: hidden;
  transition: opacity 250ms ease-in, transform 500ms ease-out;
  opacity: 0;
  transform: translateX(-50%);

  &.inview {
    opacity: 1 !important;
    transform: translateX(0%) !important;
  }

  &.lozender-right {
    transform: translateX(50%);
    margin-right: -5rem;
    right: -5rem;
    float: right;
  }

  .clearfix::after {
    content: "";
    clear: both;
  }

  .lozenger-content {
    padding: 5rem;
    margin-left: 4rem;
    max-width: 70rem;
    color: #222e44;

    .lozenger-text {
      //margin-left: 1rem;
    }

    &.lozenger-content-right {
      margin-left: 0;
    }

    .lozenger-icon-container {
      background-color: white;
      border-radius: 0.5rem;
      width: 4rem;
      height: 4rem;
      margin-bottom: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .lozenger-icon {
      stroke: #040f21;
      margin: 0;
    }

    .lozenger-image-column {
      display: flex;
      justify-self: center;
      align-items: center;
    }

    .lozenger-image {
      width: 388px;
    }

    .lozenger-title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0.6rem;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .lozenger.lozender-right {
    // margin-right: 0rem;
  }

  .lozenger-content.lozenger-content-right {
    margin-left: 3rem !important;
  }

  .lozenger-image-column {
    // max-width: 20rem;
  }
  .lozenger-text {
    //max-width: 25rem;
  }
}

@media only screen and (max-width: 768px) {
  .lozenger {
    margin-top: 0px;
    margin-left: 0;
    border-radius: 0;
  }
  .lozenger-text {
    //   max-width: 25rem;
  }

  .lozenger.lozender-right {
    margin-right: 0rem;
  }

  .lozenger-text {
    margin-left: 1rem !important;
  }

  .lozenger-content {
    padding: 2rem !important;
    margin-left: 0rem !important;

    .lozenger-title {
      font-size: 1.5rem !important;
    }
  }

  .lozenger-content.lozenger-content-right {
    margin-left: 0rem !important;
  }
}
