@import "../../variables";

.budget-table {
  width: 100%;
}

.budget-footer {
  background-color: $info-background-color;
  position: sticky;
  bottom: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  z-index: 200;
}

.budget-total {
  text-align: right;
  font-weight: bold;
  margin-right: -4px;
}

.budget-slider {
  float: none;
  clear: both;
  margin-top: 1em;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-bottom: 2em;
}

.budget-daily-rate-column {
  max-width: 100px;
}

.budget-days-column {
  min-width: 100px;
}

.budget-prep-days-column {
  min-width: 10px;
}

.budget-slider-label {
  margin-bottom: 0.7rem;
}

.budget-days-number {
  float: right;
}

.input-range {
  top: 20px;
}

.input-range__label-container {
  color: $purple-invert;
  background-color: $purple;
  padding: 0.5em 0.8em;
  border-radius: 5px;
  font-size: inherit;
  top: -7px;
}

.input-range__label--max {
  display: none;
}

.input-range__label--min {
  display: none;
}

.budget-header-number {
  text-align: right;
}

.budget-container {
  position: relative;

  .subtitle {
    position: sticky;
    top: 80px;
    background-color: $grey-darker; // $info-background-color;
    z-index: 29;
  }
}