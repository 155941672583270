@import "./../variables";

.marketing-about {
  margin-left: auto;
  margin-right: auto;
  max-width: 79rem;
  margin-bottom: 3rem;
}

.marketing-about-box {
  background-color: #222e44;
  margin-left: 5rem;
  margin-right: 5rem;
  border-radius: 30px;
}

.marketing-about-content {
  margin-left: 4rem;
  margin-right: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: white;
  font-size: 0.9rem;
  text-align: center;
  a {
    color: $green;
  }
}

@media only screen and (max-width: 768px) {
  .marketing-about-box {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
