@import "../variables";


  .badge {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 50%;
    height: 1.8rem;
    width: 1.8rem;
    color: $grey-darkest;
    font-size: 0.8rem;
    right: -5px;
    top: -5px;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &.no-notificatons {
      transform: scale(0);
    }

    &.error {
      background-color: $error-border-color;
    }

    &.warning {
      background-color: $warning-border;
    }

    &.info {
      background-color: $info-border-color;
    }
  }

