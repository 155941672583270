.help-panel__body {
    position: relative;
}

.help-panel-version {
    color: #BBBBBB;
    //margin-top: 3rem;
}

.help-panel-sub-menu__icon {
    fill: white;
}

.input-white-background {
    input::placeholder {
        color: #99a1ad
    }

    textarea::placeholder {
        color: #99a1ad !important 
    }
}