@import "./../variables";

.prices {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.prices-title {
  margin-top: 5rem;
  color: white !important;
}

.prices-container {
  //min-width: 70rem;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.price-card {
  background-color: white;
  color: #222e44;
  width: auto;
  flex: 0 1 13rem;  
  margin: 1rem;
  margin-bottom: 2rem;
  opacity: 0;
  transition: opacity 250ms;
}

.price-card-in-view {
  opacity: 1;
}

.price-is-beta {
  position: absolute;
  top: -18px;
  color: white !important;
  width: 6rem;
  min-height: 2rem;
  margin-left: 55px;
  margin-right: 60px;
}

.price-card-content {
  padding: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #222e44;
}

.price-card-header-title {
  color: #222e44;
  font-weight: bold;
}

.price-cost {
  font-size: 2rem;
  font-weight: bold;

  &.price-strike-through {
    text-decoration: line-through;
  }
}

.while-in-beta {
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.price-included {
  display: flex;
  margin-top: 0.6rem;
  justify-items: center;
}

.price-included-text {
  font-size: 0.9rem;
  margin-left: 0.8rem;
  margin-top: 0.1rem;
}

.price-button {
  margin-top: 2rem;
  color: #222e44;
  background-color: $green;
  border-color: $green;
  width: 100%;
}

.prices-terms-and-conditions {
  max-width: 60rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
