.feature-request-line-container {
    display: flex;
    justify-content: space-between;
}

.feature-request-line {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important;
}

.feature-request-table {
    width: 100%;

    .feature-request-table-title {
        padding-bottom: 0.3rem;
        padding-right: 1rem;
        max-width: 280px;
        min-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.feature-request-line-votes {
    text-align: left;

    i {
        font-size: 1rem;
        position: relative;
        top: -3px;

        &.feature-request-comment-icon {
            top: 5px;
        }
    }
}