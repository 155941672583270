@import "./../../variables";

.anonymous-background {
  background-color: $grey-darker;
  min-height: 100vh;
  background-image: url("/images/big-f.svg");
  background-repeat: no-repeat;

  background-position: right -150px top -120px;
  //background-attachment: fixed;
  position: relative;

  @media only screen and (max-width: 936px) {

    background-size: 530px;
    background-position: right -40px top -80px;
  }

  @media only screen and (max-width: 769px) {

    background-size: 530px;
    background-position: right 0px top 120px;
  }
}