@import "../../../_variables";

.user-potential-dates {
    .tag-key {
        font-weight: normal;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 0.6rem !important;
        padding-right: 0.6rem !important;
    }

    .tag-available {

        background-color: $orange !important;
        color: $purple-invert !important;
        margin-left: 1rem;
    }

    .tag-unavailable {
        background-color: $red !important;
        margin-left: 1rem;
    }

    .tag-event {
        background-color: $purple !important;
        margin-left: 1rem;
    }

    .event-unavailable {
        border-color: $red !important;
        background-color: $red !important;
        color: white !important;
    }

    .fc-h-event .fc-event-main {
        color: inherit;
    }

    .event-available {
        border-color: $orange !important;
        background-color: $orange !important;
        color: $purple-invert !important;
    }

    .fc-daygrid-event-dot {
        border-color: $purple !important;
    }

    .event-user {
        // border-color: $purple !important;
        // background-color: $purple !important;
        // color: white;
        color: white !important;
    }
}