@import "./../variables";
.marketing-bullet-points {
  clear: both;
  display: block;
  padding-top: 6rem;
  margin-top: 6rem;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  color: #222e44;
  display: flex;
  flex-wrap: wrap;
  text-align: center;

  .marketing-icon-container {
    background-color: $green;
    border-radius: 0.5rem;
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .bullet-point-icon {
    stroke: $purple-invert;
  }

  .marketing-bullet-points-title {
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .marketing-bullet-points-container {
    display: flex;
    flex-wrap: wrap;
    opacity: 1;
    transition: opacity 250ms;
  }

  .marketing-bullet-points-container-invisible {
    opacity: 0 !important;
  }

  .marketing-bullet-point {
    flex: 1 1 12rem;
    ///max-width: 13rem;
    //min-width: 7rem;
    text-align: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 3rem;
    opacity: 0;
    transition: opacity 250ms;

    &.marketing-bullet-point-in-view {
      opacity: 1;
    }
  }
}

// @media only screen and (max-width: 768px) {
//   .marketing-bullet-point {
//     width: 8rem;
//     margin-left: 0.5rem;
//     margin-right: 0.5rem;
//   }
// }
