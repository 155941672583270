.google-map {
  width: 100%;
  height: 40vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

.map-marker {
  position: relative;
  left: 0px;
  top: -30px;
}
