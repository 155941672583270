@import "../../variables";

.scene-breakdown {
  display: flex;
  flex-wrap: wrap;

  label {
    font-weight: bold;
  }

  hr {
    flex-basis: 100%;
    margin-bottom: 0.4rem;
  }
}

.scene-breakdown>div {
  margin: 0.2rem;
  margin-right: 1rem;
}

.eighth {
  font-size: 0.9rem;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.small-icon {
  &.breakdown-scene {
    margin-left: 0.4rem;
    position: relative;
    top: 0.15rem;
  }
}

.card-script-items {
  min-width: 280px;
  min-height: 100px;
}

.card-script-items-title {
  font-size: 1.1rem;
}

.card-script-items-add-button-container {
  align-self: flex-end;
  flex-grow: 1;
}


.breakdown-script-item-container {
  display: flex;
  margin-bottom: 0.2rem;

  .breakdown-script-item-drag {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .breakdown-item-name {

    display: flex;
    justify-content: left;
    white-space: nowrap;
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .breakdown-script-item-delete {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    flex-shrink: 0;
  }
}