@import "../variables";

.image-drop-area {
  border: $control-border 1px dashed;
  border-radius: 5px;
  color: $control-border;
  text-align: center;
  margin-bottom: 1rem;
  &.active {
    border-color: $purple;
    background-color: $purple;
  }
}

.image-drop-area-inner {
  padding: 2rem;
}

.image-upload-area {
  width: 100%;
  padding: 1rem;
  &.active {
    color: white;    
    background-color: $purple;
  }
}

.picture-list {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none;
  margin-top: 1rem;

  &:before {
    content: " ";
    display: table;
  }

  .picture-list-item {
    float: left;
    min-height: 1px;
    padding-right: 10px;
    width: 150px;
    margin-left: 0;

    .picture-container-label {
      height: 100px;
      //overflow: hidden;
      //background: #000;
      position: relative;
      //border: 1px solid #666;
      margin-bottom: 10px;
      display: block;
      line-height: 100px;
      text-align: center;
      //cursor: move;
      height: 210px;
      max-height: 210px;

      .delete-button {
        color: #fff;
        width: 22px;
        position: absolute;
        bottom: 2px;
        right: 30px;
        text-align: center;
        cursor: pointer;
        z-index: 20;
        line-height: 1;
        padding: 2px 2px 0 1px;

        &:hover {
          color: #ccc;
        }
      }

      .picture-container {
        display: table;
      }

      .picture {
        margin: 0 auto;
        position: absolute;

        border-radius: 5px;
      }

      .main-image-selector {
        transform: scale(1.5);
        position: absolute;
        bottom: 10px;
        left: 30px;
      }
    }
  }
}

.main-image-container {
  display: table;
  position: relative;
}

.single-image {
  max-height: 100px;
  max-width: 440px;
  border-radius: 5px;
  display: block;
}

.single-image-menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #040f2199;
  position: absolute;
  right: -35px;
  top: 0px;
  cursor: pointer;
}

.main-picture {
  max-height: 300px;
  max-width: 440px;
  border-radius: 5px;
  display: block;
}

.image-upload-icon {
  display: block;
  margin: auto;
  stroke: $control-border;
  align-items: center;
  justify-content: center;

  &.active {
    stroke: white;
  }
}

.image-menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #040f2199;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.main-image-menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #040f2199;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.image-edit-icon {
  margin-left: 2px;
  stroke: white;
}

.image-dropdown-content {
  display: none;
  position: absolute;
  border-radius: 5px;
  background-color: white;
  color: $grey-darkest;
  min-width: 160px;
  z-index: 1;
  text-align: left;
  line-height: normal;
  left: 0;
  top: 36px;

  &:before {
    content: "";
    height: 0;
    width: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    position: absolute;
    top: -6px;
    left: 12px;
    margin: 0 0 0 -3px;
    z-index: 100;
  }
}

.image-dropdown-content a {
  padding: 7px 10px;
  text-decoration: none;
  //background-color: white;
  display: block;
  color: $grey-darkest;

  &.hover {
    color: $grey-darkest;
  }
}

//.image-dropdown-content a:hover {background-color: #ddd;}

.image-menu-icon:hover .image-dropdown-content {
  display: block;
}
