@import "../../_variables";

.mode-selection-container {
    display: flex;
}

.mode-selection {
    margin-top: auto;
    margin-bottom: auto;
}

.tag-available {
    background-color: $orange !important;
    color: $purple-invert !important;
    margin-left: 1rem;
    font-weight: normal;
}

.tag-location {
    background-color: $purple !important;
    margin-left: 1rem;
    font-weight: normal;
}