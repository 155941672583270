@import "../../../variables";

.day-out-days-table-container {
  max-width: 50rem;
  max-height: 50rem;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: $button-border;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $grey-darkest;
    border-radius: 5px;
  }

  .day-out-days-table {
    border-collapse: separate;

    .day-out-days-table-header-row {
      position: sticky;
      background-color: $grey-darkest;
      top: 0;
      z-index: 2;
    }

    .day-out-days-table-actor-column-header {
      position: sticky;
      left: 0;
      top: 0;
      background-color: $grey-darkest;
    }

    .day-out-days-table-actor-column {
      position: sticky;
      background-color: $grey-darkest;
      min-width: 12rem;
      max-width: 15rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      left: 0;
      padding-right: 1rem;
      z-index: 1;

      .day-out-days-table-actor-column-number {
        display: inline-block;
        min-width: 1.5rem;
      }
    }

    .day-out-days-table-deptartment-column {
      white-space: nowrap;
    }

    .day-out-days-table-role-column {
      white-space: nowrap;
    }
  }
}

.day-out-days-order-by {
  display: flex;
}