@import url("https://fonts.googleapis.com/css?family=Manrope:regular,bold,italic");

$orange: #00ffce;
$green: $orange;

$family-serif: "Manrope", sans-serif;
$family-sans-serif: "Manrope", sans-serif;
$family-primary: "Manrope", sans-serif;
$body-family: "Manrope", sans-serif;

$grey-lighter: #98a4ad;
$grey-light: $green;
$light: $green;
$grey: #52575c;
$grey-dark: #3a3f44;
$grey-darker: #162135;
$grey-darkest: #040f21;

$background-color: #050f21;

//$green: #62c462;
$blue: #5bc0de;
$red: #ee5f5b;
$purple: #947eed;
$purple-invert: #040f21;
$title: #627795;
$button-border: #495d7a;
$control-border: #627795;
$disabled-text: #627795;

$danger: #442c3e;

$primary: $grey-darker !default;
$warning: #444740;
$warning-invert: #fff;
$warning-border: #f9e26e;

$success: #124d53;
$success-border: $orange;

$shadow: $grey-light;
$shadow-large: $grey-light;

$black-bis: rgb(18, 18, 18);

$dark: $grey-darker;

$border: $grey;

$size-7: 0.85rem;
$size-9: 0.7rem;

$dark-text: #222e44;
$select-background: #222e44;
$placeholder-color: #34435b;
$input-placeholder-color: $placeholder-color;

$body-background-color: $grey-darker;
$background: $grey-darker;
$footer-background-color: $orange;
$button-background-color: $background;
$button-border-color: $button-border;
$button-hover-border-color: white;
$button-text-hover-background-color: white;
$button-text-hover-color: #040f21;
$button-padding-vertical: 0.2em;
$button-border-color-disabled: #495d7a;

$title-color: $title;
$subtitle-color: $title;
$subtitle-strong-color: $purple;

$text: white;
$text-light: lighten($text, 10);
$text-strong: $text;

$box-shadow: none;
$box-background-color: $grey-dark;

$card-shadow: none;
$card-background-color: $grey-darkest;
$card-header-shadow: none;
$card-header-background-color: transparent;
$card-footer-background-color: transparent;
$card-color: $title;
$card-header-weight: none;
$card-content-padding: 1rem;

$link: $orange;
$link-invert: $purple;
$link-hover: lighten($link, 5);
$link-focus: darken($link, 5);
$link-active: darken($link, 15);
$link-focus-border: darken($link, 5);
$link-active-border: $link-focus-border;

$button-color: #fafafa;

$input-focus-border-color: $orange;
$input-hover-color: $grey-light;
$input-disabled-color: $disabled-text;
$input-disabled-background-color: $background;
$input-disabled-border: $grey-lighter;
$input-arrow: $primary;
$label-color: $text;

$table-color: $text;
$table-head: $grey-lighter;
$table-background-color: $grey-darkest;
$table-cell-border: 1px solid $title;
$table-head-cell-color: $title;

$table-row-hover-background-color: $grey-darker;
$table-striped-row-even-background-color: $grey-darker;
$table-striped-row-even-hover-background-color: lighten($grey-darker, 4);

$pagination-border-color: $border;

$navbar-height: 5rem;
$navbar-background-color: $grey-darkest;
$navbar-item-color: $link;
$navbar-item-hover-color: $link;
$navbar-item-active-color: $link;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-background-color: $purple;
$navbar-dropdown-background-color: white;
$navbar-dropdown-item-color: $grey-darkest;
$navbar-dropdown-item-active-color: $grey-darkest;
$navbar-dropdown-item-hover-color: $link;
$navbar-dropdown-item-active-color: $link;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-item-active-background-color: transparent;
$navbar-divider-background-color: #e8e8e8;
$navbar-divider-height: 1px;
$navbar-dropdown-arrow: white;
$navbar-burger-color: $green;

$dropdown-content-background-color: $background;
$dropdown-item-color: $text;
$dropdown-item-hover-color: $green;

$button-disabled-background-color: $grey-darker;

$tabs-toggle-link-active-background-color: $background;
$tabs-toggle-link-active-border-color: $border;
$tabs-toggle-link-active-color: #fff;
$tabs-boxed-link-active-background-color: $body-background-color;

$card-color: $grey-lighter;

$box-background-color: $grey-darkest;

$error-color: #442c3e;
$error-border-color: #f75a65;

$progress-bar-background-color: $grey-darkest;
$progress-value-background-color: $purple;

$info-background-color: #263f56;
$info-border-color: #3498db;
$tooltip-background-color: $info-background-color;
$tooltip-background-opacity: 0.2;

$modal-close-top: 50px;
$modal-close-right: 60px;
$modal-background-background-color: white;

$timeline-line: $control-border;
$navbar-breakpoint: 768px;
