@import "../../_variables.scss";

.commercial-script {


    .commercial-script-scene-row {
        display: flex;
        align-items: center;
        .scene-setting-select {
            width: 10rem;
            margin-left: 0.2rem;
            margin-right: 1rem;
        }
    }

    .commercial-script-scene-duration-cell {        
        vertical-align: middle;                
    }

    .number-column {    
        width: 5rem;
    }

    .duration-column {
        width: 5rem;
    }

    .text-field-container {
        display: flex;
        
        .text-field {
            margin-bottom: 1.75rem;
            flex-grow: 1;
        }

        .button {
            flex-shrink: 1;
        }
    }

    .commercial-script-total-duration {
        text-align: right;
        color: $title;
    }
}