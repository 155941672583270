@import "./../variables";

.socials {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.socials-container {
  display: flex;
}

.social {
  width: 3rem;
  height: 3rem;
  padding: 0.6rem;
  margin-right: 1rem;
  border-radius: 100%;
  background-color: #222e44;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white;

  &:hover {
    fill: $orange;
  }
}
