@import "./../variables";

.marketing-get-started {
  margin-left: auto;
  margin-right: auto;
  max-width: 79rem;
}

.marketing-get-started-box {
  background-color: $purple;
  margin-left: 5rem;
  margin-right: 5rem;
  border-radius: 30px;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketing-get-started-content {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.get-started-title {
  color: #222e44;
  font-weight: bold;
  font-size: clamp(1.2rem, 5vw, 2rem);
}

.get-started-register {
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
}

.try-beta-button {
  margin-top: 1rem;
  background-color: white;
  border-color: white;
  color: #222e44;
}

@media only screen and (max-width: 768px) {
  .marketing-get-started-box {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
