.shooting-days-crew {
  .dept-name {
    font-size: 1.5rem;

    a {
      color: white;
    }
  }

  .crew-roles {
    margin-left: 2em;
    margin-bottom: 2em;

    .role-name {
      text-transform: uppercase;
    }



    .crew-members {
      label {
        display: block;
      }
    }
  }
}

.role-select {
  margin-top: 4px;
}