@import '../variables';

.text-link-on-white {
    color: $purple !important;

    &:hover {
        color: $green !important;
    }

    padding-left: 0 !important;
    padding-right: 0 !important;
}