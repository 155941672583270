@import "./../../variables";

.anonymous-navbar {
  transition: -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: background-color 250ms ease-in;
  transition: min-height 250ms;
  margin-left: auto;
  margin-right: auto;
  max-width: 1930px;
  min-height: 120px;

  &.white {
    background-color: $grey-darker;
    min-height: 80px;
  }

  &.is-transparent {
    background-color: transparent !important;
  }

  .navbar-brand {
  }

  .navbar-menu.is-active
  {
    padding-bottom: 1rem;
  }

  .anonymous-hollow-button {
    background-color: transparent;
    border-color: $green !important;
    color: $grey-darkest;
  
    &:hover {
      background-color: $green;
    }
  
    &:focus {
      color: $grey-darkest;
    }
  
    &.is-active {
      background-color: $green;
      color: $button-text-hover-color;
    }

    &.white-button-text {
      color: white;

      &.is-active {
        color: $button-text-hover-color;
      }

      &:hover {
        color: $button-text-hover-color;
      }
    }
  }

  .navbarFilmanize-anonymous {
    background-color: #040f21;
  }

  .navbar-burger-anonymous {
    color: $green !important;
  }

  .navbar-burger:hover {
    background-color: transparent !important;
  }

  .navbar-burger span {
    width: 1.5rem;
    height: 2px !important;
    transition: 0.4s;
  }

  .nav-close {
    display: flex;
    align-items: center;
    justify-content: center;    
  }

  .open .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, -20px);
    transform: rotate(-45deg) translate(-5px, 1px); 
  }
  
  .open .bar2 {
    opacity: 0;
  }
  
  .open .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -8px);
    transform: rotate(45deg) translate(-6px, -2.5px);
  }

  .navbar-end {
    margin-right: 2rem;
  }

  .navbar-item a {
    font-weight: 600;
  }

  .navbar-item .button {
    height: 2.8rem;
    border-radius: 10px;
  }

  .nav-logo {
    transform-origin: bottom center;
    transition: transform 250ms ease-in;
    margin-left: 3rem;
    margin-top: 0.9rem;

    &.invisible {
      transform: scale(1.2);
    }
  }

  a {
    color: #040f21;
  }

  .is-purple {
    background-color: $purple;
    color: white;
  }

  .is-beta {
    margin-left: 0.5rem;
    position: relative;
    font-weight: normal;
    font-size: 0.6rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    top: -7px;
  }

  @media only screen and (max-width: 936px) {
    min-height: 80px !important;
  }

  @media only screen and (max-width: 768px) {
    
    .nav-logo {
      margin-left: 0.4rem;
    }

    .nav-logo.invisible {
      transform: scale(1);
    }
  }
}
