@import "../../../variables";

.shooting-days-weather {
  .observation {
    display: inline-block;
    margin: 5px;
    margin-left: 0;
    margin-right: 10px;
    background-color: $grey-darkest;
    padding-top: 0.4rem;
    padding-bottom: 0.6rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 5px;
    min-width: 6rem;

    .header {
      font-weight: bold;
    }
  }

  .icon {
    fill: white;
  }

  .icon-cell {
    height: 40px;
  }

  .climacell {
    clear: both;
    float: left;
    font-size: 0.7em;
  }

  .clear {
    clear: both;
  }

  .hourly-container {
    max-width: 50rem;

    @media only screen and (max-width: 768px) {
      max-width: 40rem;
    }

    @media only screen and (max-width: 500px) {
      max-width: 30rem;
    }

    overflow-x: auto;
    display: flex;

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background: $button-border;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $grey-darkest;
      border-radius: 5px;
    }

    .weather-header-container {
      position: sticky;
      left: 0;
      z-index: 2;
      //   border-right: $grey-darker 5px solid;
    }

    .weather-header {
      min-width: 8rem;
      margin-right: 0.75rem;
      white-space: nowrap;
    }
  }
}

.call-sheet-confirm {
  .shooting-days-weather {
    .observation {
      background-color: $grey-darker;
    }
  }
}