@import "./../../variables";

.box {
  &.budget {
    display: flex;
    background-color: #21254a;
    border-color: $purple;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin-top: 1rem;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.8rem;

    .current-budget {
      font-size: 0.6rem;
      font-weight: normal;
      justify-content: center;
      text-align: center;
    }
  }
}

.film-overview {
  min-width: 700px !important;
}

.film-overview-title {
  font-size: 1.4rem;
}

.script-uploaded {
  margin-top: 0.5rem;
  font-size: 0.7rem;
  color: $title;
  margin-bottom: 1rem;
}

.storage-progress {
  height: 8px;
  width: 500px;
  //border: 2px solid #000;
  border-radius: 4px;
  text-align: center;
  color: #fff;
}

.this-film {
  color: $purple;
}

.other-films {
  margin-left: 1rem;
  color: $green;
}

.total-storage {
  margin-left: 1rem;
  color: $title-color;
}
