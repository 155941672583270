@import '../../../variables';

.feature-request-list-table {
    width: 100%;

    .feature-request-list-table-title {
        width: 80%;
    }

    .feature-request-list-table-row {
        margin-bottom: 1rem;
    }
}

.feature-request-list-row {
    display: flex;
    justify-content: space-between;
}

.feature-request-list-table-description {
    margin-top: 0.5rem;
    padding-bottom: 1rem;
}

.feature-request-list-icons-container {
    display: flex;
}

.feature-request-list-date {
    font-size: 0.9rem;
    margin-top: 0rem;
    text-align: right;
    color: $title-color
}