.shooting-days-equipment {
  .dept-name {
    font-size: 1.1rem;

    a {
      color: white;
    }
  }

  .equipment-category {
    li {
      margin-bottom: 1rem;
    }
  }

  .crew-roles {
    margin-left: 2em;
    margin-bottom: 2em;

    .crew-members {
      label {
        display: block;
      }
    }
  }
}